@media (min-width: 500px) {
    .content {
        width: 450px;
    }
}

@media (max-width: 500px) {
    .wwd-item {
        height: 110px;
        width: 300px;
        background-color: #ffffff;
    }
}

@media (min-width: 1000px) {
    .what_we_do {
        grid-template-columns: repeat(2, 1fr);
        max-width: unset;
    }

    .content {
        width: 950px;
    }
}

.what_we_do {
    color: #6200ff;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px 20px;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

.wwd-item {
    height: 180px;
    /* border: solid 1px gray; */
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
    width: 500px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    background-color: #ffffff;
}

.wwd-item img {
    height: 100%;
}